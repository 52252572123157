import React from 'react'
import classnames from 'classnames'
import { AnchorWithArrow, Logo } from '@envato/ui'
import all from './all.svg'
import graphic from './graphic.svg'
import music from './music.svg'
import stock from './stock.svg'
import web from './web.svg'
import video from './video.svg'
import ctaImage from './cta-image.svg'
import { stringify } from 'query-string'
import styles from './hero_banner.module.css'
import { frontdoorPaths } from '../../routing/paths'
import Hyperlink from '../hyperlink/hyperlink'

const categories = [
  {
    name: 'Graphic templates',
    img: graphic,
    link: 'https://elements.envato.com/graphic-templates'
  },
  {
    name: 'Stock videos',
    img: video,
    link: 'https://elements.envato.com/stock-video'
  },
  {
    name: 'Stock photos',
    img: stock,
    link: 'https://elements.envato.com/photos/pg-1'
  },
  {
    name: 'Royalty-Free Music',
    img: music,
    link: 'https://elements.envato.com/audio/royalty-free-music'
  },
  {
    name: 'Web templates',
    img: web,
    link: 'https://elements.envato.com/web-templates'
  },
  {
    name: 'Browse all',
    img: all,
    link: 'https://elements.envato.com/all-items'
  }
]

const utmParams = (campaign) =>
  stringify({
    utm_source: 'envatocom',
    utm_medium: 'promos',
    utm_content: 'env_main',
    utm_campaign: campaign
  })

export default () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h1 className={styles.header}>Everything you need to get creative projects done</h1>
          <h2 className={styles.subHeader}>
            Bring your ideas to life with professional digital assets, resources and services
          </h2>
          <span className={styles.arrowLink}>
            <AnchorWithArrow href={frontdoorPaths.products}>Find the right product for you</AnchorWithArrow>
          </span>
        </div>
        <div className={styles.cta}>
          <div className={styles.ctaContent}>
            <Logo whichLogo='elements' className={styles.ctaLogo} />
            <h3 className={styles.ctaHeader}>
              The unlimited creative subscription. Millions of creative assets, at one low cost
            </h3>
            <span className={styles.arrowLink}>
              <AnchorWithArrow href={`https://elements.envato.com?${utmParams('elements_envatocom_main')}`}>
                Go to Envato Elements
              </AnchorWithArrow>
            </span>
          </div>
          <img
            loading='lazy'
            className={classnames(styles.ctaImage, styles.wideImage)}
            src={ctaImage}
            alt='Abstract Envato Elements isomorphic graphic'
          />
        </div>
        <div className={styles.categories}>
          {categories.map(({ name, img, link }) => (
            <div className={styles.categoryWrapper} key={name}>
              <Hyperlink href={link} className={styles.category}>
                <div className={styles.categoryImageContainer}>
                  {img && <img loading='lazy' className={styles.categoryImage} src={img} alt={`${name} vector`} />}
                </div>
                <span className={styles.categoryName}>{name}</span>
              </Hyperlink>
            </div>
          ))}
        </div>
      </div>
      <img
        loading='lazy'
        className={classnames(styles.ctaImage, styles.narrowImage)}
        src={ctaImage}
        alt='Abstract Envato Elements isomorphic graphic'
      />
      <div className={styles.subBanner}>
        <div className={styles.subBannerContainer}>
          <div className={styles.subBannerChild}>
            <Logo className={styles.subBannerLogo} whichLogo='market' />
            <span className={styles.subBannerHeader}>
              Find the perfect asset from the world's leading creative marketplace
            </span>
            <span className={styles.arrowLink}>
              <AnchorWithArrow href={`https://themeforest.net?${utmParams('market_envatocom_main')}`}>
                Go to Envato Market
              </AnchorWithArrow>
            </span>
          </div>
          <div className={styles.subBannerChild}>
            <Logo className={styles.subBannerLogo} whichLogo='placeit' />
            <span className={styles.subBannerHeader}>Create mockups, logos, social posts and videos in seconds</span>
            <span className={styles.arrowLink}>
              <AnchorWithArrow href={`https://placeit.net?${utmParams('placeit_envatocom_main')}`}>
                Go to Placeit
              </AnchorWithArrow>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
