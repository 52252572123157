import React from 'react'
import HeroBanner from '../components/hero_banner/hero_banner'
import TeamSection from '../components/team_section'
import Press from '../components/press'
import styles from './home.module.css'

export default function HomePage() {
  return (
    <div className={styles.root}>
      <HeroBanner />
      <Press />
      <TeamSection />
    </div>
  )
}
